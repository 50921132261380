// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils-integral';
const Boxes = {
box1: function () {
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.circle.highlight=false;
JXG.Options.line.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 8, 8, -8],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.layer['image']=14;
brd1.suspendUpdate();
//Title
makeResponsive(brd1);
placeTitle(brd1, 'Number of Tangents to a Circle', '(Drag point P and observe how many tangents can be drawn from it to the circle)');
//Variables
var centerx = 0;
var centery = 0;

let switchStatus = true;

//Tape
var x0_tape = 5;
var y0_tape = 1;
var size_tape = 2;


//Radius
var radius = brd1.create('slider',[[11,1],[13,1],[0,3,10]], {visible: false, snapToGrid: false ,face:'circle', size:4, strokeWidth: 2, name:'r', strokeColor:'#5B43FF', fillColor: '#5B43FF',  baseline: { strokeColor: '#5B43FF'}, highline: { strokeColor: '#5B43FF'}, postLabel: '', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

//Circle
var pt0 = brd1.create('point', [centerx, centery],{face:'o' , name:'O', size:4, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var c1 = brd1.create('circle', [pt0, [function(){return pt0.X() + radius.Value()}, function(){return pt0.Y()}]], {strokeColor: '#5B43FF', strokeWidth: 4, fixed:true});

//OP
var p_P = brd1.create('point', [5,0], {name: 'P', attractors: [c1], attractorDistance: 0.5 , snatchDistance: 1, face:'circle', size:4, label:{offset: [-5, 20], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var seg_op = brd1.create('segment', [p_P, pt0], {fixed: true, strokeColor: '#5B43FF', strokeWidth: 3, dash:3});

var perp1 = brd1.create('perpendicular', [seg_op, p_P], {visible: false, fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});

//Tangents
var p_M = brd1.create('midpoint', [seg_op], {visible: false, name: 'M', face:'circle', size:2, label:{offset: [-5, 20], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var c2 = brd1.create('circle', [p_M, p_P], {visible: false, strokeColor: '#5B43FF', strokeWidth: 4, fixed:true});
var c3 = brd1.create('circle', [p_P, pt0], {visible: false, strokeColor: '#5B43FF', strokeWidth: 4, fixed:true});

//Intersection
var p_Q = brd1.create('intersection', [c1, c2, 0], {face:'o' , name:'Q', size:4, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p_R = brd1.create('intersection', [c1, c2, 1], {face:'o' , name:'R', size:4, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var p_Q2 = brd1.create('intersection', [c3, perp1, 0], {face:'o' , name:'Q2', size:4, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p_R2 = brd1.create('intersection', [c3, perp1, 1], {face:'o' , name:'R2', size:4, strokeColor: '#5B43FF', fillColor: '#5B43FF', fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

//PR and PQ
var seg_pr = brd1.create('segment', [p_P, p_Q], {fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});
var seg_pq = brd1.create('segment', [p_P, p_R], {fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3});
var seg_qr2 = brd1.create('segment', [p_Q2, p_R2], {fixed: true, strokeColor: '#FF3B3B', strokeWidth: 3, visible: function(){if( seg_op.L() > (radius.Value() - 0.1) && seg_op.L() < (radius.Value() + 0.1) ){return true} else {return false}}});

//Radius line
var seg_radius1 = brd1.create('segment', [pt0, p_Q], {fixed: true, strokeColor: '#5B43FF', strokeWidth: 3, dash: 3, visible: true});
var seg_radius2 = brd1.create('segment', [pt0, p_R], {fixed: true, strokeColor: '#5B43FF', strokeWidth: 3, dash: 3, visible: true});

//Text
var txt1 = brd1.create('text', [0, -5,  function(){ if(seg_pr.L() > 0.1){
                                                        return 'Point P is outside the circle. ';
                                                    }
                                                    else if(seg_op.L() > (radius.Value() - 0.1) && seg_op.L() < (radius.Value() + 0.1)){
                                                        return 'Point P is on the circle.';
                                                    }
                                                    else{
                                                        return 'Point P is inside the circle.';
                                                    }
}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

var txt1 = brd1.create('text', [0, -6,  function(){ if(seg_pr.L() > 0.1){
                                                        return 'Number of Tangents = 2 ';
                                                    }
                                                    else if(seg_op.L() > (radius.Value() - 0.1) && seg_op.L() < (radius.Value() + 0.1)){
                                                        return 'Number of Tangents = 1';
                                                    }
                                                    else{
                                                        return 'Number of Tangents = 0';
                                                    }
}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

brd1.unsuspendUpdate();
}
}
export default Boxes;
